@import "normalize";

@import "settings";

// Include all foundation
@import "foundation";

// Or selectively include components
// @import
//   "foundation/components/accordion",
//   "foundation/components/alert-boxes",
//   "foundation/components/block-grid",
//   "foundation/components/breadcrumbs",
//   "foundation/components/button-groups",
//   "foundation/components/buttons",
//   "foundation/components/clearing",
//   "foundation/components/dropdown",
//   "foundation/components/dropdown-buttons",
//   "foundation/components/flex-video",
//   "foundation/components/forms",
//   "foundation/components/grid",
//   "foundation/components/inline-lists",
//   "foundation/components/joyride",
//   "foundation/components/keystrokes",
//   "foundation/components/labels",
//   "foundation/components/magellan",
//   "foundation/components/orbit",
//   "foundation/components/pagination",
//   "foundation/components/panels",
//   "foundation/components/pricing-tables",
//   "foundation/components/progress-bars",
//   "foundation/components/reveal",
//   "foundation/components/side-nav",
//   "foundation/components/split-buttons",
//   "foundation/components/sub-nav",
//   "foundation/components/switches",
//   "foundation/components/tables",
//   "foundation/components/tabs",
//   "foundation/components/thumbs",
//   "foundation/components/tooltips",
//   "foundation/components/top-bar",
//   "foundation/components/type",
@import "foundation/components/offcanvas";
//   "foundation/components/visibility";

$hh-color1: #ebebeb;
$hh-color2: #803838;

$hh-color1-light: scale-color($hh-color1, $lightness: 25%);
$hh-color2-light: scale-color($hh-color2, $lightness: 25%);

$hh-pad2: rem-calc(15);
$hh-pad1: rem-calc(30);




.text-bold {
    font-weight: bold;
}

.container {
    &.shaded {
        /*min-height: 800px;*/
        /*box-shadow: 0px 0px 8px rgba(0,0,0,0.24);*/
        box-shadow: 0px 0px 6px rgba(128,56,56,0.33);
        background: #FFF;
    }

    /*max-width: 1600px;*/
    margin: 0 auto;
}


footer {
    padding: rem-calc(15);
    font-size: rem-calc(15);
}

.full-height {
    /*height: 100%;*/
}

a {
    color: $hh-color2;
    transition: color 0.3s;

    &:hover {
        color: $hh-color2-light;
        transition: color 0.3s;
    }
}

body {
    background: url('/img/crossword.png');
    /*height: 100%;*/
    font-family: 'Open Sans', sans-serif;
}

nav {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}


.stormynd {
    @media #{$large-up} {
        height: 800px;
    }

    @media #{$small-only} {
        height: 300px;
    }

    height: 500px;
    /*background: url('/img/stormynd.jpg') center center no-repeat;*/
    background: $hh-color1;
    background-size: cover;

    /*padding: $hh-pad1;*/

    .intro {
        position: absolute;
        width: 75%;
        left: rem-calc(20);
        bottom: rem-calc(20);
        border-top: 1px solid #FFF;
        padding-top: rem-calc(10);
        text-transform: uppercase;
        color: #FFF;

        strong {
            font-size: rem-calc(22);
        }

        span {
            line-height: rem-calc(60);
            font-weight: 300 !important;
            font-size: rem-calc(60);
        }

        @media #{$small-only} {
            strong {
                font-size: rem-calc(18);
            }

            span {
                line-height: rem-calc(40);
                font-size: rem-calc(40);
            }
        }
    }
}


.content {
    header {
        h1, h2 {
            font-size: rem-calc(24);
            margin-bottom: 0;
            padding-bottom: 0;
            color: #ccc;
            font-weight: 600 !important;
            text-transform: uppercase;
        }

        padding-bottom: rem-calc(12);
        margin-bottom: rem-calc(24);
        border-bottom: 1px solid #CCC;
    }
}


ul.frontpage-orbit {
    ul {
        list-style: none;
    }
}

.herbergi-orbit {
    li {
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}

.sidebar {
    ul {
        li {
            color: #FFF;
        }
    }

    @extend .content;

    header {
        h2 {
            color: #FFF;
        }
    }

    h3 {
        font-size: rem-calc(20);
    }

    h2, h3, h4 {
        color: #FFF;
    }
}

article {
    h3 {
        font-size: rem-calc(20);
    }

    min-height: 800px;
    padding: $hh-pad1;

    @extend .content;
}



.map {
    height: 500px;
}



.hh2-bg {
    position: relative;
    z-index: 1;
    padding: $hh-pad1;
    background-color: $hh-color2;
}

#bgimages {
    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
}


.listitem {
    position: relative;

    &--container {
        min-height: 300px;
        display: block;
        position: relative;
        overflow: hidden;
        transition: all 0.3s;

        &:hover {
            background-size: contain;
            transition: all 0.3s;
        }
    }

    margin-bottom: $hh-pad1;

    &--overlay {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $hh-color2-light;
        opacity: 0;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.3;
            transition: opacity 0.3s;
        }
    }

    &--caption {
        background: rgba($hh-color2, 0.85);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        padding: $hh-pad2;

        h3 {
            color: $hh-color1;
            font-size: rem-calc(18);
            text-transform: uppercase;
            padding: 0;
            margin: 0;
        }
    }
}



#b_availSubmit {
    width: 100% !important;

    clear: both !important;

    input {
        float: left !important;        
    }

    float: left !important;
    
    display: block !important;
}
.hh1-bg {
    h3 {
        color: $hh-color2;
        display: block;
        padding-bottom: rem-calc(15);
    }
    
    .offcanvas-menu-container {
        display: table-cell;
        height: 30px;
        vertical-align: middle;
    }

    .logo.main {
        margin-top: $hh-pad1;
        margin-bottom: $hh-pad1;
    }

    a.mobile-button {
        font-size: rem-calc(20);
        i {
            font-weight: bolder;
        }
    }

    hr {
        border: solid $hh-color2;
        border-width: 1px 0 0;
        opacity: 0.25;
    }

    &.mobile {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    padding: $hh-pad1;
    background-color: $hh-color1;

    nav > ul > li > ul {
        margin-left: rem-calc(10);
    }

    nav > ul > li > a {
        font-size: rem-calc(18);
        text-transform: uppercase;
    }

    nav > ul > li > ul > li a {
        &:before {
            content: "- ";
        }
    }

    a {
        color: $hh-color2;
        transition: color 0.25s;

        &:hover {
            color: $hh-color2-light;
            transition: color 0.25s;
        }
    }

    a.social-icon {
        font-size: rem-calc(44);
    }

    nav {
        li.active > a {
            font-weight: bold;
        }

        ul {
            li {
                line-height: rem-calc(34);
                font-size: rem-calc(18);
            }
        }
    }
}





.left-off-canvas-menu {
    z-index: 2000;

    background: $hh-color2;

    a {
        color: #FFF;
    }

    nav {
        &#mobile {
            ul {
                padding: 0; 
                margin: 0;

                li {
                    ul {
                        background: $hh-color1-light;

                        li {
                            border-bottom: 1px solid #DDD;

                            a {
                                padding-top: rem-calc(5);
                                padding-bottom: rem-calc(5);
                                color: #888;
                            }

                            ul {
                                display: none;
                            }
                        }
                    }
                }
            }
            
            a {
                display: block;
                padding: rem-calc(10);
            }

            li {
                display: block;
                padding: 0;
                margin: 0;
                border-bottom: 1px solid $hh-color2-light;
            }
        }
    }
}